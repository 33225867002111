<template>
  <div v-if="ready">
    <h1 id="page-title">{{ assetTypeFormatted }} Details</h1>
    <section class="asset-details-container">
      <div class="asset-details col-one panel">
        <div class="asset-meta">
          <div class="asset-meta-header">
            <div>
              <h2>{{ assetName }}</h2>
            </div>
            <i :class="assetIconClass" class="k-asset-icon"></i>
          </div>
          <!--    common metadata-->
          <div class="row">
            <div>Asset type: </div>
            <div>{{ assetTypeFormatted }}</div>
          </div>
          <div class="row">
            <div>Description: </div>
            <div>{{ assetDescription }}</div>
          </div>
          <!--    asset specific meta-->
          <div class="asset-specific-meta row" v-if="'completion_types' in asset">
            <div class='col'>Completion types: </div>
            <div v-if="assetCompletionTypes.length > 1"><ul><li v-for="ct in assetCompletionTypes">{{ ct }} </li></ul></div>
            <div v-else><span v-for="ct in assetCompletionTypes">{{ ct }} </span></div>
          </div>
          <div class='asset-specific-meta row' v-if="'kloud_compatible' in asset">
            <div class='col'>Kloud Compatible: </div>
            <div>{{ assetKloudCompatible }}</div>
          </div>
          <div class='asset-specific-meta row' v-if="'is_active' in asset">
            <div>Archived: </div>
            <div>{{ assetArchived }}</div>
          </div>
          <div class='asset-specific-meta row' v-if="asset.gitlab_repo_url">
            <div>Repository URL: </div>
            <div>
              <a :href="assetRepoURL">{{ assetRepoURL }}</a>
              <router-link class="btn btn-outlined" :to="konmanUrl" v-if="hasKonmanPermissions">Open with konman <i class="fa-duotone fa-arrow-up-right-from-square"></i></router-link>
            </div>
          </div>
          <div class='asset-specific-meta row' v-if="'language' in asset">
            <span>Language:</span>
            <span>{{ assetProgrammingLanguage }}</span>
          </div>
          <div class='asset-specific-meta row' v-if="'last_deployed' in asset">
            <span>Last Deployed:</span>
            <span>{{ assetLastDeployed }}</span>
          </div>
          <div class='asset-specific-meta row' v-if="'date_recorded' in asset">
            <span>Date Recorded:</span>
            <span>{{ assetDateRecorded }}</span>
          </div>
          <div class='asset-specific-meta row' v-if="'date_uploaded' in asset">
            <span>Date Uploaded:</span>
            <span>{{ assetDateUploaded }}</span>
          </div>
          <div v-if="'topics' in asset" class="asset-specific-meta row">
            <span>Topics: </span>
            <div><span v-for="topic in assetTopics" class="badge">{{ topic }}</span></div>
          </div>
          <div class="row">
            <span>Tags:</span>
            <div><span v-for="tag in assetTags" class="badge">{{ tag }} </span></div>
          </div>
        </div>
      </div>
    </section>
    <section class="asset-details-container">
      <div class="asset-activity col-one panel">
        <div class="asset-activity-header">
          <h2>Activity</h2>
          <k-dropdown class="time-series-options" v-if="asset.activity" v-model="timeSeriesOption"
                      :type="'alt'"
                      :options="['Last Year', 'Last Month', 'Last Week', 'Last 24 Hours']"
                      :placeholder="'Select trend'"></k-dropdown>
        </div>
        <div class="asset-activity-body" v-if="asset.activity">
          <span v-if="assetType === 'pak' || assetType === 'quiz'">Submissions </span>
          <span v-else>Completions</span>
          <span>in the {{ timeSeriesOption }}</span>
          <k-chart-line v-if="timeSeries.data.reduce((a, b) => a + b, 0) > 0" :xaxisData="timeSeries.labels"
                   :seriesOne="timeSeries.data" :seriesOneTitle="timeSeriesOption" :key="timeSeries.labels.length"></k-chart-line>
          <span v-else>There has been no activity on this asset for {{ daysSinceLastActive }} Days. Try increasing the date range</span>
        </div>
        <span v-else>
          There has been no activity on this asset for at least 365 Days
        </span>
      </div>
    </section>
    <section v-if="learnerAssetFeedbackReady" class="asset-details-container">
      <learner-asset-feedback
        :feedback="learnerAssetFeedback"
      ></learner-asset-feedback>

    </section>
    <section class="asset-details-container" v-if="$permissions.hasPermission('manage_assets')">
      <div class="asset-usage col-one panel">
        <div class="asset-usage-header">
          <h2>Programme & Module Releases</h2>
        </div>
        <span v-if="asset.programmes_and_modules">
          Listed programmes & modules '{{ assetName }}' has been released to. Click on the links below to be directed to
          the learner programme and module landing pages
        </span>
        <span v-else>
          {{ assetName }} has not been released in any programme or module
        </span>
        <k-table
            v-if="asset.programmes_and_modules"
            :headers="kableReleaseHeaders"
            :rows="kableReleaseRows"
            :max="10"
            :hideable="false"
            :panel="false">
          ></k-table>
      </div>
    </section>
    <section class="asset-details-container" v-if="$permissions.hasPermission('manage_blueprints')">
      <div class="asset-usage col-one panel">
        <div class="asset-usage-header">
          <h2>Programme & Module Blueprints</h2>
        </div>
        <span v-if="asset.programme_and_module_blueprints">
          Listed programmes & modules blueprints <em>'{{ assetName }}'</em> is used in. Click on the links below to be
          directed to blueprint pages. Note, not all modules blueprints are in a programme blueprint
        </span>
        <span v-else>
          {{ assetName }} is not used in any programme or module blueprint
        </span>
        <k-table class="blueprint-table"
            v-if="asset.programme_and_module_blueprints"
            :headers="kableBlueprintHeaders"
            :rows="kableBlueprintRows"
            :max="10"
            :hideable="false"
            :panel="false">
          ></k-table>
      </div>
    </section>
  </div>
</template>

<style scoped>
/* asset-details-container */
#page-title {
  margin: 0;
}

.asset-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 15px 0;
  margin: 15px 0;
  align-items: stretch;
}

.asset-details-container .panel {
  padding: 15px;
  margin: 0;
  width: 100%;
  height: 47%;
}

.asset-details-container span {
  color: var(--kate-type-primary);
}

.panel ul li {
  color: var(--kate-primary);
}

.panel ul {
  padding-left: 15px;
}

.time-series-options {
  width: 150px;
}

.asset-usage {
  flex: 1;
  width: 50%;
}

.col-two {
  flex: 1;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

.asset-meta-header,
.asset-activity-header {
  display: flex;
  justify-content: space-between;
}

.asset-activity-header h2 {
  margin: 0;
}

.asset-meta-header > i {
  font-size: xx-large;
  margin: 10px;
}

.asset-usage-header {
  width: 100%;
}

.asset-usage .blueprint-table {
  margin-top: 15px;
}

.row {
  display: flex;
  width: 100%;
  margin-left: 0;
}

.row > :first-child {
  width: 200px;
}

.row > :last-child {
  color: var(--kate-type-primary);
}

.row > * {
  flex: 0 0 auto;
  width: calc(100% - 200px);
}
</style>

<script>
import useGlobalStore from '../../../stores/global';
import ErrorMixin from '../../../mixins/error-mixins';
import timeMixins from '../../../mixins/time-mixins';
import getOrNull from '../../../modules/get-or-null';
import PageReadyMixin from '../../../mixins/page-ready-mixin';
import KTable from '../../../components/k-table.vue';
import KChartLine from '../../../components/charts/k-chart-line.vue';
import KDropdown from '../../../components/k-dropdown.vue';
import assetIconMixin from '../../../mixins/asset-icon-mixin';
import textFormattingMixins from '../../../mixins/text-formatting-mixins';
import { ASSET_TYPES } from '../../../constants';
import LearnerAssetFeedback from './learner-asset-feedback.vue';

const DAY_MS = 24 * 60 * 60 * 1000;

export default {
  components: {
    KDropdown,
    KChartLine,
    KTable,
    LearnerAssetFeedback,
  },

  mixins: [ErrorMixin, timeMixins, PageReadyMixin, assetIconMixin, textFormattingMixins],

  data() {
    return {
      store: useGlobalStore(),
      asset: undefined,
      timeSeriesOption: 'Last Year',
      learnerAssetFeedback: undefined,
      learnerAssetFeedbackReady: false,
    };
  },

  beforeMount() {
    if (this.assetType in ASSET_TYPES) {
      this.getAsset();
      this.getLearnerAssetFeedback();
    } else {
      this.$logger.warn(`Asset type ${this.assetType} not supported`);
      this.$router.push({ name: '404' });
    }
  },

  computed: {
    ready() {
      return Boolean(this.asset);
    },
    appUrl() {
      return this.store.appUrl;
    },
    timeSeries() {
      return {
        labels: this.getBinLabels(this.timeSeriesOption),
        data: this.getTimeSeriesData(this.timeSeriesOption, this.asset.activity),
      };
    },
    daysSinceLastActive() {
      const timestamps = this.asset.activity ? this.asset.activity.map(ts => ts.completed_on) : [];
      timestamps.sort();
      if (timestamps.length === 0) { // i.e. no activity for 365 days
        return 'at least 365';
      }
      // calc time diff between now and most recent activity timestamp then convert from ms to day
      return Math.floor((this.currentTime() - new Date(timestamps.at(-1))) / DAY_MS);
    },
    kableReleaseHeaders() {
      return {
        programme_name: {
          name: 'Programme',
          sortable: true,
          filterable: true,
          type: 'url',
        },
        module_name: {
          name: 'Module',
          sortable: true,
          filterable: true,
          type: 'url',
        },
        app_link: {
          name: 'View on KATE',
          filterable: false,
        },
      };
    },
    kableReleaseRows() {
      return this.asset.programmes_and_modules.map(pm => ({
        programme_name: {
          text: pm.programme_name,
          path: {
            name: 'manage_programme',
            params: {
              programmeId: pm.programme_id,
            },
          },
        },
        module_name: {
          text: pm.module_name,
          path: {
            name: 'manage_module',
            params: {
              programmeId: pm.programme_id,
              moduleId: pm.module_id,
            },
          },
        },
        app_link: `<a href="${this.kateAppAssetUrl(pm)}" target="_blank"><i class="fas fa-external-link-alt"></i></a>`,
      }));
    },
    kableBlueprintHeaders() {
      return {
        programme_name: {
          name: 'Programme Blueprint',
          sortable: true,
          filterable: true,
          type: 'url',
        },
        module_name: {
          name: 'Module Blueprint',
          sortable: true,
          filterable: true,
          type: 'url',
        },
      };
    },
    kableBlueprintRows() {
      return this.asset.programme_and_module_blueprints.map(pmbp => ({
        programme_name: pmbp.programme_blueprint_id ? {
          text: pmbp.programme_blueprint_name,
          path: {
            name: 'curriculum_manage_programme_blueprint',
            params: {
              programmeBlueprintId: pmbp.programme_blueprint_id,
            },
          },
        } : undefined,
        module_name: {
          text: pmbp.programme_blueprint_module_name,
          path: {
            name: 'manage_programme_blueprint_module',
            params: {
              programmeBlueprintModuleId: pmbp.programme_blueprint_module_id,
              programmeBlueprintId: pmbp.programme_blueprint_id,
            },
          },
        },
      }));
    },
    assetType() {
      return this.$route.params.assetType;
    },
    assetRoutingName() {
      return ASSET_TYPES[this.assetType]?.routeName;
    },
    assetId() {
      return this.$route.params.assetId;
    },
    assetTypeFormatted() {
      return ASSET_TYPES[this.assetType].prettyName;
    },
    assetIconClass() {
      return this.getAssetIcon(this.assetType);
    },
    assetName() {
      return getOrNull('name', this.asset) || getOrNull('title', this.asset);
    },
    assetDescription() {
      return getOrNull('description', this.asset);
    },
    assetTags() {
      return 'tags' in this.asset ? this.asset.tags : undefined;
    },
    assetCompletionTypes() {
      return getOrNull('completion_types', this.asset);
    },
    assetKloudCompatible() {
      const kloudCompatible = getOrNull('kloud_compatible', this.asset);
      if (typeof kloudCompatible === 'boolean') {
        return kloudCompatible ? 'True' : 'False';
      }
      return kloudCompatible;
    },
    assetArchived() {
      const isActive = getOrNull('is_active', this.asset);
      if (typeof isActive === 'boolean') {
        return isActive ? 'False' : 'True';
      }
      return isActive;
    },
    assetRepoURL() {
      return getOrNull('gitlab_repo_url', this.asset);
    },
    assetProgrammingLanguage() {
      return getOrNull('language', this.asset);
    },
    assetLastDeployed() {
      const strDate = getOrNull('last_deployed', this.asset);
      if (strDate) {
        return this.parseTimestamp(strDate);
      }
      return strDate;
    },
    assetDateRecorded() {
      const strDate = getOrNull('date_recorded', this.asset);
      if (strDate) {
        return this.parseTimestamp(strDate);
      }
      return strDate;
    },
    assetDateUploaded() {
      const strDate = getOrNull('date_uploaded', this.asset);
      if (strDate) {
        return this.parseTimestamp(strDate);
      }
      return strDate;
    },
    assetTopics() {
      return getOrNull('topics', this.asset);
    },
    assetEndpoint() {
      return `/api/curriculum/admin/${ASSET_TYPES[this.assetType].apiRoot}/${this.assetId}`;
    },
    hasKonmanPermissions() {
      return this.$permissions.hasPermission('access_konman');
    },
    konmanUrl() {
      if (!this.hasKonmanPermissions) {
        return undefined;
      }
      const assetType = this.assetType === 'pak' ? 'pak' : 'lu';
      return { name: 'spin_kod_page', params: { assetId: this.assetId, assetType } };
    },
  },
  methods: {
    pageReadyCallback() {
      this.registerCrumbs();
    },
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: `${this.assetTypeFormatted} Overview`,
          path: {
            name: `curriculum_assets_${this.assetRoutingName}`,
          },
        },
        {
          text: this.assetName,
          active: true,
        },
      ]);
    },

    getAsset() {
      this.$logger.info(`Getting ${this.assetType} - ${this.assetId}`, undefined, true);
      this.$http.get(this.assetEndpoint)
        .then(result => {
          this.$logger.info(`Got ${this.assetType} - ${this.assetId}`);
          this.asset = Object.values(result.data)[0];
        }).catch(err => {
          if (!this.$http.isWarning(err)) {
            this.$logger.error('Error getting asset data', undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn('No asset data found');
            this.asset = undefined;
          }
        });
    },

    getLearnerAssetFeedback() {
      this.$logger.info(`Getting ${this.assetType} - ${this.assetId}`);
      this.learnerAssetFeedbackReady = false;
      this.$http.get(`/api/curriculum/admin/${this.assetType}/${this.assetId}/learner_feedback`)
        .then(result => {
          this.$logger.info(`Got learner feedback for ${this.assetType} - ${this.assetId}`);
          this.learnerAssetFeedback = result.data;
        }).catch(err => {
          if (!this.$http.isWarning(err)) {
            this.$logger.error('Error getting learner asset feedback', undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn('No learner asset feedback found');
            this.learnerAssetFeedback = undefined;
          }
        }).then(() => {
          this.learnerAssetFeedbackReady = true;
        });
    },

    kateAppAssetUrl(progModMeta) {
      // ASSET TODO: Consider using $router.resolve() to generate link instead - would require tweaks to routes.js
      // to standardise route names / and parameters
      // Use route name for now to generate link but we should update the interface so programmes_and_modules
      // data has an asset_type and module_asset_id field instead
      const assetRoute = ASSET_TYPES[this.assetType].routeName;
      const assetIdField = `module_${this.assetType}_id`;
      return `${this.appUrl}/modules/${progModMeta.module_id}/${assetRoute}/${progModMeta[assetIdField]}`;
    },
    currentTime() {
      return new Date();
    },
    getBinLabels(trend) {
      const now = this.currentTime();
      if (trend === 'Last Year') {
        return [...Array(12).keys()].map(i => {
          now.setMonth(now.getMonth() - (i ? 1 : 0));
          return `${now.toString().slice(4, 7)} ${now.toString().slice(13, 15)}`;
        }).reverse();
      }
      if (trend === 'Last Month') {
        return [...Array(28).keys()].map(i => {
          now.setDate(now.getDate() - (i ? 1 : 0));
          return now.toString().slice(8, 10);
        }).reverse();
      }
      if (trend === 'Last Week') {
        return [...Array(7).keys()].map(i => {
          now.setDate(now.getDate() - (i ? 1 : 0));
          return now.toString().slice(0, 3);
        }).reverse();
      }
      return [...Array(24).keys()].map(i => {
        now.setHours(now.getHours() - (i ? 1 : 0));
        return `${now.toString().slice(16, 18)}:00`;
      }).reverse();
    },
    getBinLabel(trend, timestamp) {
      if (trend === 'Last Year') {
        return `${timestamp.toString().slice(4, 7)} ${timestamp.toString().slice(13, 15)}`;
      }
      if (trend === 'Last Month') {
        return timestamp.toString().slice(8, 10);
      }
      if (trend === 'Last Week') {
        return timestamp.toString().slice(0, 3);
      }
      return `${timestamp.toString().slice(16, 18)}:00`;
    },
    getTimeSeriesData(trend, activity) {
      const now = this.currentTime();
      // We are not interested in timestamps that are too far back in time
      // For each timestamp we need to get the bin label e.g. Mon, Tue, Wed
      // The time intervals we look at are cyclic so if we start in Friday, if we go back far enough we'll get to the
      // Fri of the previous week. Any timestamps in that week, will be counted as events that happened on this friday
      // which we don't want
      // We also need to deduct any time that has elapsed between a week ago (using trend='Last Week' as an example)
      // and the following midnight.
      const maxTimeDiff = {
        'Last Year': 365 * 24 * 3600000 - (24 * 3600000 - (now.getMilliseconds() + now.getSeconds() * 1000 + now.getMinutes() * 60000 + now.getHours() * 3600000)),
        'Last Month': 28 * 24 * 3600000 - (24 * 3600000 - (now.getMilliseconds() + now.getSeconds() * 1000 + now.getMinutes() * 60000 + now.getHours() * 3600000)),
        'Last Week': 7 * 24 * 3600000 - (24 * 3600000 - (now.getMilliseconds() + now.getSeconds() * 1000 + now.getMinutes() * 60000 + now.getHours() * 3600000)),
        'Last 24 Hours': 24 * 3600000 - (3600000 - (now.getMilliseconds() + now.getSeconds() * 1000 + now.getMinutes() * 60000)),
      };
      const dateLabels = this.getBinLabels(trend);
      const counts = {};
      const timestamps = activity.map(ts => ts.completed_on);
      timestamps.sort();
      timestamps.forEach(ts => {
        const diff = now - new Date(ts);
        const tsStr = this.getBinLabel(trend, new Date(ts));
        if (diff < maxTimeDiff[trend] && dateLabels.indexOf(tsStr) >= 0) {
          if (!(tsStr in counts)) {
            counts[tsStr] = 0;
          }
          counts[tsStr] += 1;
        }
      });
      return dateLabels.map(i => (counts[i] ? counts[i] : 0));
    },
  },
};

</script>
