<template>
    <k-modal :show="showEditModal" @close="closeEditModal" class="edit-competency-modal">
      <template #header>
        <h3>Edit Competency <i class="fas fa-pencil-alt"></i></h3>
      </template>
      <template #body>
        <div class="edit-competency-body">
          <fieldset class="form-programme form-entry">
            <label for="edit-competency-category">Category</label>
            <k-dropdown
              id="edit-competency-category"
              :options="categoryOptions"
              v-model="localCompetency.category"
              :showSearch="false"
            ></k-dropdown>
          </fieldset>
          <fieldset class="form-programme form-entry">
            <label for="edit-competency-label">Label</label><br>
            <small class="info"><i>K1, K2, S1, B1 etc...</i></small>
            <input
              id="edit-competency-label"
              type="text"
              class="form-control"
              v-model="localCompetency.label"
            >
          </fieldset>
          <fieldset class="form-programme form-entry">
            <label for="edit-competency-description">Description</label>
            <textarea
              id="edit-competency-description"
              class="form-control"
              v-model="localCompetency.description"
            ></textarea>
          </fieldset>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-danger" @click="closeEditModal">Cancel</button>
        <button class="btn btn-success" @click="updateCompetency" :disabled="!validForm">Save</button>
      </template>
    </k-modal>
</template>

<style scoped>
.edit-competency-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.edit-competency-modal .btn {
  margin-left: 1rem;
}
</style>

<script>
import KModal from '../../../components/k-modal.vue';
import KDropdown from '../../../components/k-dropdown.vue';
import ErrorMixin from '../../../mixins/error-mixins';

export default {
  components: {
    KModal,
    KDropdown,
  },
  mixins: [ErrorMixin],
  props: {
    showEditModal: {
      type: Boolean,
      default: false,
    },
    selectedCompetency: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'closeEditModal'],
  data() {
    return {
      localCompetency: {
        id: null,
        category: '',
        label: '',
        description: '',
      },
    };
  },
  watch: {
    selectedCompetency: {
      handler(newVal) {
        this.localCompetency = { ...newVal };
      },
      immediate: true,
    },
  },
  computed: {
    apiEndpoint() {
      return `/api/curriculum/${this.isBlueprint ? 'blueprints/' : ''}programmes/${this.entityId}/competencies`;
    },
    entityId() {
      return Number(this.isBlueprint ? this.$route.params.programmeBlueprintId : this.$route.params.programmeId);
    },
    entityName() {
      return this.isBlueprint ? 'Programme blueprint' : 'Programme';
    },
    validForm() {
      return Boolean(
        this.localCompetency.label?.trim()
        && this.localCompetency.description?.trim(),
      );
    },
    categoryOptions() {
      return [
        { id: 'Knowledge', name: 'Knowledge' },
        { id: 'Skill', name: 'Skill' },
        { id: 'Behaviour', name: 'Behaviour' },
      ];
    },
  },
  methods: {
    closeEditModal() {
      this.$emit('closeEditModal');
    },
    updateCompetency() {
      this.$logger.info('Updating competency', {
        id: this.localCompetency.id,
        updatePayload: this.localCompetency,
      });
      this.$Loading.start();

      this.$http.put(`${this.apiEndpoint}/${this.localCompetency.id}`, {
        category: this.localCompetency.category,
        label: this.localCompetency.label,
        description: this.localCompetency.description,
      })
        .then(() => {
          this.$logger.info(`Successfully updated ${this.entityName} competency`);
          this.$ktoast.success(`${this.entityName} competency updated.`);
          this.$emit('update');
          this.closeEditModal();
        })
        .catch(err => {
          this.$logger.error(`Could not update ${this.entityName} competency`, err);
          this.showError(err);
        })
        .then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>
