<template>
  <div>
    <h1 v-if="programme">{{ programme.name }}</h1>
    <k-nav-tabs :currentTab="currentTab" :tabs="showTabs"></k-nav-tabs>
    <router-view v-bind="pageProps" @refresh-programme="getProgramme"></router-view>
  </div>
</template>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import KNavTabs from '../../components/k-nav-tabs.vue';
import useModuleChapterStore from '../../stores/module-chapter-store';

export default {
  components: {
    KNavTabs,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      programme: undefined,
      programmeReady: false,
      moduleChapterStore: useModuleChapterStore(),
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgramme();
  },

  unmounted() {
    this.moduleChapterStore.clearChapters();
  },

  watch: {
    currentTab() {
      // Ensure crumbs stay in place when switching tabs
      this.registerCrumbs();
    },
  },

  computed: {
    tabs() {
      return [
        {
          name: 'Learners',
          routeName: 'manage_programme_members',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
        {
          name: 'Content',
          routeName: 'manage_programme_content',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
        {
          name: 'Achievements',
          routeName: 'manage_programme_achievements',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
        {
          name: 'Competencies',
          routeName: 'manage_programme_competencies',
          routeParams: {
            programmeId: this.programmeId,
          },
        },
      ];
    },
    currentTab() {
      return this.$route.name;
    },
    showTabs() {
      if (this.$featureFlags.isEnabled('kate-competencies-dashboard-feature')) {
        return this.tabs;
      }
      return this.tabs.filter(tab => tab.name !== 'Competencies');
    },
    programmeId() {
      return Number(this.$route.params.programmeId);
    },
    pageProps() {
      return {
        programme: this.programme,
        programmeReady: this.programmeReady,
        isBlueprint: false,
      };
    },
  },

  methods: {
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Manage Programmes',
          path: {
            name: 'programmes_overview',
          },
        },
        {
          text: this.programme?.name || this.programmeId,
          active: true,
        },
      ]);
    },
    getProgramme() {
      this.programmeReady = false;
      this.$logger.info('Getting programme', { programmeId: this.programmeId }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}`).then(res => {
        this.$logger.info('Successfully retrieved programme', { programmeId: this.programmeId });
        this.programme = res.data;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$router.push({ name: '404' });
        } else {
          this.$logger.error('Could not retrieve programme', { programmeId: this.programmeId }, err);
          this.showError(err);
        }
      }).then(() => {
        this.programmeReady = true;
        this.registerCrumbs();
      });
    },
  },
};
</script>
