<template>
  <div>
    <h1 v-if="programmeBlueprint">{{ programmeBlueprint.name }}</h1>
    <k-nav-tabs :currentTab="currentTab" :tabs="showTabs"></k-nav-tabs>
    <router-view v-bind="pageProps" @refresh="getProgrammeBlueprint"></router-view>
  </div>
</template>

<script>
import KNavTabs from '../../../components/k-nav-tabs.vue';
import ErrorMixin from '../../../mixins/error-mixins';
import useModuleChapterStore from '../../../stores/module-chapter-store';

export default {
  components: {
    KNavTabs,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      programmeBlueprint: undefined,
      programmeBlueprintReady: false,
      moduleChapterStore: useModuleChapterStore(),
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeBlueprint();
  },

  unmounted() {
    this.moduleChapterStore.clearChapters();
  },

  watch: {
    currentTab() {
      // Ensure crumbs stay in place when switching tabs
      this.registerCrumbs();
    },
  },

  computed: {
    tabs() {
      return [
        {
          name: 'Content',
          routeName: 'manage_programme_blueprint_content',
          routeParams: {
            programmeBlueprintId: this.programmeBlueprintId,
          },
        },
        {
          name: 'Achievements',
          routeName: 'manage_programme_blueprint_achievements',
          routeParams: {
            programmeBlueprintId: this.programmeBlueprintId,
          },
        },
        {
          name: 'Competencies',
          routeName: 'manage_programme_blueprint_competencies',
          routeParams: {
            programmeBlueprintId: this.programmeBlueprintId,
          },
        },
      ];
    },
    currentTab() {
      return this.$route.name;
    },
    showTabs() {
      if (this.$featureFlags.isEnabled('kate-competencies-dashboard-feature')) {
        return this.tabs;
      }
      return this.tabs.filter(tab => tab.name !== 'Competencies');
    },
    programmeBlueprintId() {
      return Number(this.$route.params.programmeBlueprintId);
    },
    pageProps() {
      if (this.currentTab === 'manage_programme_blueprint_content') {
        return {
          programmeBlueprint: this.programmeBlueprint,
          programmeBlueprintReady: this.programmeBlueprintReady,
        };
      } if (this.currentTab === 'manage_programme_blueprint_achievements') {
        return {
          programme: this.programmeBlueprint,
          programmeReady: this.programmeBlueprintReady,
          isBlueprint: true,
        };
      } if (this.currentTab === 'manage_programme_blueprint_competencies') {
        return {
          programme: this.programmeBlueprint.programmeId,
          programmeReady: this.programmeBlueprintReady,
          isBlueprint: true,
        };
      }
      return undefined;
    },
  },

  methods: {
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Programme Blueprints',
          path: {
            name: 'curriculum_blueprints_programmes',
          },
        },
        {
          text: this.programmeBlueprint.name,
          active: true,
        },
      ]);
    },
    getProgrammeBlueprint() {
      this.$logger.info('Getting programme blueprint');
      this.programmeBlueprintReady = false;
      this.$http.get(`/api/curriculum/blueprints/programmes/${this.programmeBlueprintId}`).then(res => {
        this.programmeBlueprint = res.data;
        this.$logger.info('Successfully retrieved programme blueprint');
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$router.push('/404');
        } else {
          this.$logger.autowarn('Could not retrieve programme blueprint', { programmeBlueprintId: this.programmeBlueprintId }, err);
          this.showError(err);
        }
      }).then(() => {
        this.programmeBlueprintReady = true;
        this.registerCrumbs();
      });
    },
  },
};
</script>
