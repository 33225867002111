<template>
  <div class="chapter" :class="editable ? 'right-pane' : 'left-pane'">
    <div class="chapter-content-container">
      <div class="chapter-header-container" @click="handleClick">
        <h2 v-if="!editTitleMode" @click="chapterToggle">
          <div>{{ chapter.name }}</div>
          <k-label v-if="chapter.label" :label="chapter.label"></k-label>
          <button v-if="editable" class="edit-btn" title="Edit" aria-label="Edit">
            <i class="fas fa-edit" @click="editTitleMode = true"></i>
          </button>
          <i v-if="!editable" class="fas" :class="`${showChapterContent ? 'fa-angle-up' : 'fa-angle-down'}`"></i>
        </h2>
        <form v-else class="edit-chapter">
          <fieldset class="form-entry">
            <input v-model="chapterTitle" maxlength="100" placeholder="Enter title here..." class="form-input"/>
            <k-dropdown class="dropdown dark" placeholder="Select a Label"
              :showSearch="false"
              :options="chapterLabelOptions"
              :clear-option=true
              v-model="chapterLabel">
            </k-dropdown>
          </fieldset>

          <k-tooltip text="Update chapter title and label" text-align="center">
            <button @click="updateChapterTitle" class="btn btn-success save-btn" :disabled="invalidTitle" title="Update changes" aria-label="Update changes">
              <i class="fas fa-check"></i>
            </button>
          </k-tooltip>
        </form>
      </div>
      <div v-if="editable && isChapterSelected" class="chapter-instruction">
        <p>Single click on an asset to remove it from the chapter, click and drag asset to re-arrange order.</p>
      </div>
      <ol v-if="showChapterContent">
        <draggable
          v-model="chapter.assets"
          draggable=".asset-items"
          ghost-class="ghost"
          @change="$emit('assets-reordered', chapter.assets)"
          :disabled="!isChapterSelected">
          <asset-item v-for="asset in chapter.assets" class="asset-items"
            :key="`${asset.asset_type}-${asset.id}`"
            :asset="asset"
            :interaction-enabled="assetInteractionEnabled"
            :is-chapter-selected="isChapterSelected"
            :selected-override="overrideSelection(asset)"
            @asset-click="$emit('select-asset', asset)"
          >
          </asset-item>
        </draggable>
      </ol>
    </div>
    <div v-if="editable" class="chapter-icon-container">
      <k-tooltip text="Delete chapter">
        <button class="btn btn-danger delete-btn" @click="$emit('delete-chapter')" title="Delete chapter" aria-label="Delete chapter">
          <i class="fas fa-trash-alt"></i>
        </button>
      </k-tooltip>
      <i class="fas fa-grip-vertical"></i>
    </div>
  </div>
</template>

<style>
.ghost .item.release-option {
  border: 4px solid var(--kate-primary);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>

<style scoped>
.chapter {
  position: relative;
  padding: 15px 5px;
  display: flex;
  gap: 15px;
}

.chapter h2 {
  margin: 0;
  padding-bottom: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 15px;
}

.chapter-instruction p {
  font-weight: 400;
  text-align: center;
}

.chapter ol {
  padding: 0;
}

fieldset.form-entry {
  min-width: 100%;
}

.form-entry input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  margin: 5px 0;
  text-overflow: ellipsis;
  color: var(--kate-type-light);
  background-color: var(--input-background);
  border: var(--input-border);
}

.edit-chapter {
  display: flex;
  align-items: center;
  gap: 15px;
}

.chapter-content-container {
  flex: 1;
}

.chapter-icon-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  text-align: center;
}

.chapter-header-container {
  display: flex;
  justify-content: space-between;
}

.chapter-icon-container button,
.chapter-header-container button {
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  font-size: 16px;
  padding: 5px 10px;
}

.chapter-header-container h2 button.edit-btn {
  padding: 10px;
}

.chapter-header-container h2 button.edit-btn:hover {
  color: var(--kate-primary);
}

.chapter-header-container button.save-btn {
  color: var(--kate-type-dark);
  background-color: var(--kate-success);
}

.chapter-header-container button.save-btn:hover {
  background-color: var(--kate-success-dark);
}

.chapter-icon-container button.delete-btn i {
  color: var(--kate-danger-alt);
}

.chapter-icon-container button.delete-btn:hover i {
  color: var(--kate-danger);
}

span.chapter-label {
  font-family: hk-grotesk-light, sans-serif;
  font-size: 0.5em;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  background-color: var(--kate-button-primary);
}

</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import KTooltip from '../../../../../components/k-tooltip.vue';
import KDropdown from '../../../../../components/k-dropdown.vue';
import AssetItem from './asset-item.vue';
import KLabel from '../../../../../components/k-label.vue';

export default {
  components: {
    AssetItem,
    draggable: VueDraggableNext,
    KTooltip,
    KDropdown,
    KLabel,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    assets: {
      type: Array,
      required: true,
    },
    editable: Boolean,
    assetInteractionEnabled: Boolean,
    isChapterSelected: Boolean,
    defaultChapterTitles: {
      type: Array,
      default: () => [],
    },
    disabledAssets: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chapter: {},
      editTitleMode: false,
      showChapterContent: true,
      chapterTitle: '',
      chapterLabel: '',
      chapterLabelOptions: [
        'Trainer Led',
        'Self Study',
        'Group Project',
      ],
    };
  },

  mounted() {
    this.chapter = { name: this.name, label: this.label, assets: this.assets };
    this.chapterTitle = this.chapter.name;
    this.chapterLabel = this.chapter.label;
  },

  watch: {
    name() {
      this.chapter.name = this.name;
      this.chapterTitle = this.chapter.name;
    },
    label() {
      this.chapter.label = this.label;
      this.chapterLabel = this.chapter.label;
    },
    assets() {
      this.chapter.assets = this.assets;
    },
    chapter() {
      this.$emit('input', this.chapter);
    },
  },

  computed: {
    invalidTitle() {
      if (!this.editable) {
        return undefined;
      }
      return !this.name || this.defaultChapterTitles.indexOf(this.name) !== -1;
    },
  },

  methods: {
    updateChapterTitle() {
      this.chapter.name = this.chapterTitle;
      this.editTitleMode = false;
      this.chapter.label = this.chapterLabel;
      this.$emit('update-title-and-label', { name: this.chapterTitle, label: this.chapterLabel });
    },
    handleClick() {
      if (!this.editTitleMode) {
        this.$emit('select-chapter');
      }
    },
    chapterToggle() {
      if (!this.editable) {
        this.showChapterContent = !this.showChapterContent;
      }
    },
    overrideSelection(asset) {
      return Boolean(this.disabledAssets.find(a => a.id === asset.id && a.asset_type === asset.asset_type));
    },
  },
};
</script>
