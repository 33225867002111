<template>
  <div>
    <div class="module-blueprint-controls">
      <slot name="instructions">
      <p>To withdraw a released module, click on the edit button in the module card then click <i>Withdraw</i>. The module release date will be unset and it will no longer be available to students.</p>
      <p>Note that no changes (removing, assigning badges, reordering, scheduling a release or withdrawing) will be reflected on <b>EDUKATE</b> until you save your changes via the <i>Save Changes</i> button.</p>
      </slot>
      <div class="module-blueprint-actions">
        <button class="btn btn-danger" @click="$emit('reset')">Reset</button>
        <button class="btn btn-success" @click="$emit('saveChanges')" :disabled="disableCommit">Save Changes</button>
      </div>
    </div>
    <div class="nav-tabs-wrapper">
      <k-tooltip :text="this.indicesInEditMode.length > 0 ? 'Some modules are still being edited' : ''">
        <ul class="nav nav-tabs">
          <li class="tablink-container" :class="{ 'active': moduleContent==='curriculum', }">
            <a @click="setModuleContent('curriculum')" :class="{disabled: disabledClass}">Curriculum</a>
          </li>
          <li class="tablink-container" :class="{ 'active': moduleContent==='milestone', }" >
            <a @click="setModuleContent('milestone')" :class="{disabled: disabledClass}">Milestones</a>
          </li>
          <li class="tablink-container" :class="{ 'active': moduleContent==='copy', }" >
            <a @click="setModuleContent('copy')" :class="{disabled: disabledClass}">Add module</a>
          </li>
        </ul>
      </k-tooltip>
    </div>
    <div v-if="moduleContent !== 'copy'">
      <draggable
        v-model="trackModules"
        draggable=".k-module-item"
        ghost-class="ghost"
        @change="$emit('orderChanged')"
        :disabled="!isDraggable"
      >
        <k-release-module
          v-for="mod, index in trackModules"
          class='k-module-item'
          :module="mod"
          :target-module-type="mod.addedModule ? mod.targetModuleType : moduleType"
          :source-module-type="mod.addedModule ? mod.sourceModuleType : moduleType"
          :key="mod.id"
          :id="mod.id"
          :tags="mod.tags"
          :number="index + 1"
          :isNewlyAdded="Boolean(mod.addedModule)"
          :released="mod.released"
          :draggable="isDraggable"
          :allow-remove="true"
          :allow-edit="true"
          :show-badges="true"
          @update="updatePayload => $emit('release-update', mod.index, updatePayload)"
          @remove="$emit('remove', mod.index)"
          @cancel="$emit('cancel', mod.index)"
          @editMode="editMode => toggleEditMode(mod.index, editMode)"
        ></k-release-module>
      </draggable>
      <p v-if="trackModules.length === 0">The are no {{ track }} modules in the programme</p>
    </div>
    <div v-else>
      <abstract-copy-modules
        :target-programme-type="moduleType === 'module' ? 'programme' : 'programme_blueprint'"
        :programme-start-date="programmeStartDate"
        @add-modules="(modules) => addModules(modules)"
      ></abstract-copy-modules>
    </div>
  </div>
</template>

<style scoped>
.module-blueprint-controls {
  padding-bottom: 20px;
}

.module-blueprint-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.tablink-container a.disabled {
  color: var(--kate-disabled);
  pointer-events: none;
  cursor: not-allowed;
}
</style>

<style>
.ghost .create-programme-module-card {
  border: 4px solid #5fede3;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.add-module-to-programme-modal .modal-container {
  max-width: 80%;
  max-height: 70%;
}
</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import ErrorMixin from '../../../mixins/error-mixins';
import ModuleTypeMixin from '../../../mixins/module-type-mixin';
import KReleaseModule from './release-module.vue';
import KTooltip from '../../../components/k-tooltip.vue';
import { MODULE_TYPES } from '../../../constants';
import AbstractCopyModules from './abstract-copy-modules.vue';
import useReleaseModuleStore from '../../../stores/release-module-store';

export default {
  components: {
    AbstractCopyModules,
    KTooltip,
    draggable: VueDraggableNext,
    'k-release-module': KReleaseModule,
  },

  props: {
    draggable: {
      type: Boolean,
      default: true,
    },
    disableCommit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    moduleType: {
      type: String,
      default: 'module',
      validator: value => Object.keys(MODULE_TYPES).includes(value),
    },
    programmeStartDate: {
      type: String,
      default: null,
    },
  },

  mixins: [ErrorMixin, ModuleTypeMixin],

  data() {
    return {
      track: 'curriculum',
      indicesInEditMode: [],
      moduleContent: 'curriculum',
      releaseModuleStore: useReleaseModuleStore(),
    };
  },

  unmounted() {
    this.releaseModuleStore.clearModules();
  },

  computed: {
    trackModules: {
      get() {
        return this.modelValue.filter(mod => mod.track === this.track);
      },
      set(val) {
        this.$emit('update:modelValue', this.reorderedModules(val));
      },
    },
    disabledClass() {
      return this.indicesInEditMode.length > 0 ? 'disabled' : '';
    },
    isDraggable() {
      return this.draggable && this.indicesInEditMode.length === 0;
    },
  },

  methods: {
    toggleEditMode(i, editMode) {
      if (editMode) {
        this.indicesInEditMode.push(i);
      } else if (this.indicesInEditMode.indexOf(i) >= 0) {
        this.indicesInEditMode = this.indicesInEditMode.filter(item => item !== i);
      }
    },
    reorderedModules(trackMods) {
      // Number of tracked modules shouldn't change in the component
      // Add and removing is done in the component above
      const updatedModules = [];
      let count = 0;
      this.modelValue.forEach(mod => {
        if (mod.track !== this.track) {
          updatedModules.push(mod);
        } else {
          updatedModules.push(trackMods[count]);
          count++;
        }
      });
      return updatedModules;
    },
    setModuleContent(content) {
      this.moduleContent = content;
      if (['curriculum', 'milestone'].includes(content)) {
        this.track = content;
      }
    },
    addModules(modules) {
      modules.forEach(mod => {
        this.$emit('add', mod);
      });
    },
  },
};
</script>
