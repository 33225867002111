<template>
  <div class="module-card-header">
      <div class="module-card-header-title">
        <!-- pending withdrawl tooltip -->
        <k-tooltip
          v-if="showPendingWithdrawalTooltip"
          :text="pendingWithdrawalText">
          <i class="fas fa-exclamation-triangle pending-withdrawal"></i>
        </k-tooltip>
        <k-tooltip v-if="isUncommitted" :text="uncommittedModuleText">
          <div class="tags">
            <span class="success">New <i class="fa-duotone fa-sparkles"></i></span>
          </div>
        </k-tooltip>
        <h3> {{ module.name }}</h3>
        <!-- only for programmes -->
        <div v-if="moduleUpdatePending">
          <k-tooltip class="pending-label-container" :text="moduleUpdatePendingText">
            <span class="pending-label">Ready to update</span>
          </k-tooltip>
        </div>
        <div v-else-if="isModule">
          <span v-if="hasModuleReleased" class="released-label badge">Released <i class="fas fa-check"></i></span>
          <span v-else class="unreleased-label k-text-primary badge">Not Released</span>
        </div>
        <!-- end -->
      </div>
      <div v-if="allowEdit" class="module-card-header-buttons">
        <button v-if="revertButtonShowing" @click.stop="$emit('revertPendingChanges')" class="btn revert-change btn-danger">
          Revert changes <i class="fas fa-undo"></i>
        </button>
        <k-tooltip :text="editModeText" class="edit-update-btn">
          <button
            @click.stop="$emit('toggleEditMode')"
            class="btn"
            :class="inEditMode ? 'update btn-success' : 'edit btn-primary'"
            :title="inEditMode ? 'Update' : 'Edit'">
            {{ inEditMode ? "Update" : "Edit" }}
            <i v-if="!inEditMode" class="fas fa-pen"></i>
            <i v-else-if="inEditMode" class="fas fa-check"></i>
          </button>
        </k-tooltip>
      </div>
  </div>
</template>
<style scoped>
.module-card-header,
.module-card-header-title,
.module-card-header-buttons {
  display: flex;
  gap: 15px;
}

.module-card-header {
  padding: 15px 0 0;
  justify-content: space-between;
  align-items: center;
}

.module-card-header h3 {
  margin-top: 0;
}

.module-card-header-title {
  align-items: center;
}

.pending-label-container {
  display: flex;
}

.pending-withdrawal {
  color: var(--kate-warning);
  font-size: 1.5em;
}

span.released-label {
  color: var(--kate-type-dark);
  background-color: var(--kate-success);
}

span.unreleased-label {
  background-color: var(--kate-danger-alt);
  white-space: nowrap;
}

span.pending-label {
  color: var(--kate-type-dark);
  background-color: var(--kate-warning);
}

@media (max-width: 767px) {
  .module-card-header,
  .module-card-header-title {
    display: flex;
    flex-wrap: wrap;
  }

  .module-card-header h3 {
    width: 100%;
    margin-bottom: 0;
  }
}

</style>
<script>
import TimeMixin from '../../../../mixins/time-mixins';
import KTooltip from '../../../../components/k-tooltip.vue';
import useReleaseModuleStore from '../../../../stores/release-module-store';

export default {
  components: { KTooltip },
  mixins: [TimeMixin],
  emits: ['toggleEditMode', 'revertPendingChanges'],
  props: {
    module: {
      type: Object,
      required: true,
    },
    isModule: {
      type: Boolean,
      required: true,
    },
    released: {
      type: Boolean,
      default: false,
    },
    isUncommitted: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    inEditMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      releaseModuleStore: useReleaseModuleStore(),
    };
  },

  computed: {
    showPendingWithdrawalTooltip() {
      return (
        this.isModule
        && this.released
        && this.releaseDate
        && !this.dateIsPast(this.releaseDate)
      );
    },
    releaseDate() {
      return this.releaseModuleStore.getModuleValue(this.module.index, 'release_date');
    },
    hasModuleReleased() {
      // will be true only for programmes
      return this.isModule && this.released;
    },
    moduleUpdatePending() {
      return !this.inEditMode && this.isModule && this.updatePending;
    },
    moduleUpdatePendingText() {
      return 'Click on \'Save Changes\' to confirm the update. To cancel the update, click on this card and select \'Cancel Update\'.';
    },
    pendingWithdrawalText() {
      return `This module will be withdrawn${this.releaseDate && !this.dateIsPast(this.releaseDate) ? ` until ${this.formatDateString(this.releaseDate)}` : ' from release.'}`;
    },
    uncommittedModuleText() {
      return 'This module has not yet been committed to the database and added to the programme';
    },
    editModeText() {
      if (this.inEditMode) {
        return "Stash changes and exit edit mode. When ready to commit, click 'Save Changes'";
      }
      return 'Edit release and completion dates';
    },
    updatePending() {
      return this.releaseModuleStore.hasModuleChanges(this.module.index) || false;
    },
    revertButtonShowing() {
      return (this.updatePending && this.inEditMode);
    },
  },
};
</script>
