<template>
  <div class="asset-feedback panel">
    <div class="asset-usage-header panel-header">
      <h2>Feedback</h2>
    </div>
    <div v-if="feedback" class="panel-body">
      <div class="learner-feedback-overview panel">
        <h3>Overview</h3>
        <div class="overview-content">
          <div class="overview-stats">
            <span><i class="fa-solid fa-list"></i> <b>{{ feedback.feedback_entries }}</b> Feedback Entries</span>
            <span><i class="fa-duotone fa-comments"></i> <b>{{ feedback.feedback_comment_count }}</b> {{ pluralize('Comment', feedback.feedback_comment_count, false) }}</span>
            <span><i class="fas fa-check-circle"></i> <b>{{ feedback.completion_count }}</b> {{ pluralize('Completion', feedback.completion_count, false) }}</span>
          </div>
          <hr>
          <div class="chart-container">
            <k-chart-pie
              :seriesData="feedbackChartSeries"
              :seriesLabels="['Upvotes', 'No votes', 'Downvotes']"
              :showLegend="false"
            ></k-chart-pie>
          </div>
          <div class="chart-legend">
            <span><i class="fas fa-thumbs-up"></i> <b>{{ feedback.upvotes }}</b> {{ pluralize('Upvote', feedback.upvotes, false) }}</span>
            <span><i class="fas fa-thumbs-up neutral"></i> <b>{{ feedback.no_votes }}</b> {{ pluralize('No-vote', feedback.no_votes, false) }}</span>
            <span><i class="fas fa-thumbs-down"></i> <b>{{ feedback.downvotes }}</b> {{ pluralize('Downvote', feedback.downvotes, false) }}</span>
          </div>
        </div>
      </div>
      <div class="learner-feedback-notes panel">
        <div class="lf-header">
          <h3>Comments</h3>
          <div class="header-actions">
            <k-text-search v-model="filterText"></k-text-search>
            <div v-if="feedback.feedback_comment_count > 0" class="sort">
              <button class="btn btn-outlined btn-sort" aria-label="Sort" title="Sort" @click="toggleSort">
                <span>
                  Sort
                  <i class="fas" :class=" sortDescending ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-up'"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="lf-content">
          <div class="lf-overview">
            <k-note v-for="comment in filteredComments" :key="comment.comment"
              :userLink="true"
              :note="comment"
              :filterText="filterText"
            ></k-note>
          </div>
        </div>
      </div>
    </div>
    <span v-else>
      No feedback submitted for this asset.
    </span>
  </div>

</template>

<style>
.chart-legend .fa-thumbs-up.neutral,
.note-header i.fa-thumbs-up.neutral {
  transform: rotate(-90deg);
  color: var(--kate-stroke-dark) !important;
}
</style>

<style scoped>
.panel-body::before,
.panel-body::after {
  content: unset;
}

.panel-body,
.lf-header,
.header-actions,
.overview-stats,
.chart-legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.overview-stats span b,
.chart-legend span b {
  color: var(--kate-type-light);
}

.overview-stats span i,
.chart-legend span i {
  margin-right: 5px;
}

.overview-content hr {
  width: 25%;
  border: 1px solid var(--kate-background-alt);
}

.asset-feedback > span {
  color: var(--kate-type-primary);
}

.panel-body {
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;
}

.learner-feedback-overview.panel,
.learner-feedback-notes.panel {
  padding: 15px;
  margin: 0;
  flex: 1;
  border-radius: 15px;
}

.learner-feedback-notes {
  flex: 1;
}

.overview-content,
.lf-overview {
  margin-top: 20px;
}

.lf-header {
  align-items: flex-start;
}

.lf-overview {
  height: 332px;
  overflow-y: auto;
}

.chart-legend {
  margin-top: 15px;
}

.chart-legend .fa-thumbs-up {
  color: var(--kate-stroke-light);
}

.chart-legend .fa-thumbs-down {
  color: var(--kate-danger-alt);
}

</style>

<script>
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import PluralMixin from '../../../mixins/plural-mixin';
import TimeMixin from '../../../mixins/time-mixins';
import KChartPie from '../../../components/charts/k-chart-pie.vue';
import KTextSearch from '../../../components/k-text-search.vue';
import KNote from '../../components/k-note.vue';

export default {
  components: {
    KTextSearch,
    KChartPie,
    KNote,
  },

  props: {
    feedback: Object,
  },

  mixins: [PluralMixin, TimeMixin],

  data() {
    return {
      filterText: '',
      sortDescending: false,
    };
  },

  computed: {
    sortedComment() {
      return sortObjectArray(this.feedback.feedback_data, 'date_timestamp', !this.sortDescending);
    },

    filteredComments() {
      if (this.filterText.length < 1) {
        return this.sortedComment;
      }
      const regOption = new RegExp(this.filterText, 'ig');
      return this.feedback.feedback_data.filter(x => {
        // Can search by note content, date created/updated, author or module.
        if ((x.comment && x.comment.match(regOption))
            || (x.name && x.name.match(regOption))
            || (this.parseTimestamp(x.date_timestamp) && this.parseTimestamp(x.date_timestamp).match(regOption))
            || (x.module_name && x.module_name.match(regOption))) {
          return true;
        }
        return false;
      });
    },

    feedbackChartSeries() {
      return [this.feedback.upvotes, this.feedback.no_votes, this.feedback.downvotes];
    },
  },

  methods: {
    toggleSort() {
      this.sortDescending = !this.sortDescending;
    },
  },
};
</script>
