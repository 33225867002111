<template v-else>
  <div>
    <div class="date-edits">
      <k-date-picker inputId="new-release-date-input"
        label="Release Date"
        class="date-picker"
        mask="MMM D, YYYY"
        type="datetime"
        v-model="releaseDate"
        :modelConfig="{ timeAdjust: '00:00:00' }"
      ></k-date-picker>
      <button v-if="releaseDate" class="btn btn-warning withdraw" @click.stop.prevent="withdrawModule">Withdraw Module</button>
    </div>
    <div class="date-edits">
      <k-date-picker inputId="new-expected-completion-date-input"
        label="Expected Completion Date"
        class="date-picker"
        mask="MMM D, YYYY"
        type="datetime"
        v-model="expectedCompletionDate"
        :modelConfig="{ timeAdjust: '00:00:00' }"
      ></k-date-picker>
    </div>
    <badge-editor
      :badgeOptions="badgeOptions"
      :badgesSortedByThreshold="badgesSortedByThreshold"
      @remove="onRemoveBadge"
      @onAddBadge="onAddBadge"
    />
  </div>
</template>

<style scoped>
button.withdraw {
  margin-right: 5px;
  display: inline;
  width: fit-content;
  align-self: flex-start;
}

.date-edits .date-picker {
  width: 50%;
  margin-bottom: 10px;
}

.date-edits {
  display: flex;
  gap: 15px;
}

@media (max-width: 767px) {
  .date-edits {
    display: flex;
    flex-wrap: wrap;
  }

  .date-edits .date-picker {
    width: 100%;
    margin-bottom: 0;
  }
}
</style>
<script>
import TimeMixins from '../../../../mixins/time-mixins';
import KDatePicker from '../../../../components/k-date-picker.vue';
import BadgeEditor from './badge-editor.vue';
import { sortObjectArray } from '../../../../modules/sort-by-object-property';
import useReleaseModuleStore from '../../../../stores/release-module-store';

export default {
  components: {
    KDatePicker,
    BadgeEditor,
  },

  mixins: [TimeMixins],

  props: {
    module: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      releaseModuleStore: useReleaseModuleStore(),
    };
  },

  computed: {
    releaseDate: {
      get() {
        return this.releaseModuleStore.getModuleValue(this.module.index, 'release_date');
      },
      set(newVal) {
        this.releaseModuleStore.setModuleProperty(this.module.index, 'release_date', newVal);
      },
    },
    expectedCompletionDate: {
      get() {
        return this.releaseModuleStore.getModuleValue(this.module.index, 'expected_completion_date');
      },
      set(newVal) {
        this.releaseModuleStore.setModuleProperty(this.module.index, 'expected_completion_date', newVal);
      },
    },
    badgeOptions() {
      return this.module.programmeBadges?.filter(badge => !this.releaseModuleStore.getModuleProperty(this.module.index, 'badges_in_module')?.map(eb => eb.id).includes(badge.id)) || [];
    },
    badgesSortedByThreshold() {
      return sortObjectArray(this.releaseModuleStore.getModuleProperty(this.module.index, 'badges_in_module') ?? this.module?.badges_in_module);
    },
  },

  methods: {
    onAddBadge(badge) {
      this.releaseModuleStore.addBadgeToModule(this.module.index, badge);
    },
    onRemoveBadge(badgeId) {
      this.releaseModuleStore.removeBadgeFromModule(this.module.index, badgeId);
    },
    withdrawModule() {
      this.releaseModuleStore.withdrawModule(this.module.index);
    },
  },
};
</script>
